<template>
  <div class="home-contrain "  ref='dxPopper'>
    <header-back name='电销卡'>
    </header-back>
    <section class="cen top44">
      <div class="cen-img">
        <img src='../../assets/image/dx.png'
        >
      </div>
    </section>
  <div class="main-cen">
     <div class="cen-h-tit">￥<span>200</span>.00</div>
        <van-cell title="套餐类型">
          <div
            class="reason"
            slot="right-icon"
            style="max-width:76%"
          >
            <span> 虚商电销卡 高频不封号（封号包换） 归属地可选 无限打语音卡超长通话分钟 可选套餐 120元月租1000分钟/188元月租2000分钟</span>
          </div>
        </van-cell>
         <van-cell title="购买数量">
                    <div class="reason" slot="right-icon" style="max-width:76%">
                        <van-stepper v-model="cardNum" style="color: red;" />
                    </div>
                </van-cell>
        <div class="address-f-m">
          <van-form
            label-align="left"
            label-width="4.8em"
          >
            <div class="address-tit"><span>实名信息</span></div>
            <van-field
              v-model="username"
              name="姓名 "
              label="姓名 "
              :border='false'
              placeholder="请输入姓名（已加密）"
              autocomplete="off"
            />
            <van-field
              v-model="userIdCard"
              name="身份证号 "
              :border='false'
              label="身份证号 "
              placeholder="请输入身份证号（已加密）"
              autocomplete="off"
            />
            <van-field
              v-model="usertel"
              type="tel"
              name="手机号码 "
              :border='false'
              label="手机号码 "
              placeholder="请输入联系电话，并保持畅通"
              autocomplete="off"
            />
            <div class="address-tit"><span>填写配送地址</span></div>
            <van-field
              readonly
              clickable
              name="area"
              :value="uservalue"
              label="地区"
              placeholder="省市区"
              @click="showArea = true"
            />
            <van-popup
              v-model="showArea"
              round
              position="bottom"
            >
              <van-cascader
                title="请选择所在地区"
                :options="options"
                @close="showArea = false"
                @finish="onFinish"
              />
            </van-popup>
            <van-cell title="">
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #title>
                <span class="custom-title">详细</span>

              </template>
              <template #right-icon>
                <van-popover
                  v-model="showPopover"
                  :actions="searchResult"
                  @select="onSelect"
                  style="width:70% "
                >
                  <template #reference>
                    <van-field
                      v-model="userdetail"
                      label=""
                      rows="2"
                      autosize
                      clearable
                      type="textarea"
                      id='dxTextare'
                      placeholder="街道门牌/楼层房间号等信息"
                      style="padding:0"
                      @input='onDetailInput'
                    />
                  </template>
                </van-popover>
              </template>
            </van-cell>

          </van-form>
        </div>
        <div class="contrain-footer">
          <p class="title">*请填写真实身份信息，未满16岁、一证五号、信息错误将无法 发货。</p>
          <van-button
            block
            type="info"
             color="linear-gradient(180deg, #F56B6E 0%, #DF3033 100%)"
            @click="submitClik()"
          >
            立即购买
          </van-button>
        </div>
      </div>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import variables from "@/styles/variables.scss";
import area from "@/utils/pcas-code.json";
export default {
  components: { HeaderBack },
  data() {
    return {
      cardNum: 1,
      username: "",
      usertel: "", //电话
      userIdCard: "",
      city: "", //市
      county: "", //区
      province: "", //省
      street: "",
      areaCode: "",
      uservalue: "",
      showArea: false, //省市区
      areaList: AreaList,
      userdetail: ""
    };
  },
  methods: {
    onFinish({ selectedOptions }) {
      console.log(selectedOptions);
      // this.show = false;
      this.uservalue = selectedOptions.map(option => option.text).join("/");

      this.province = selectedOptions[0].text;

      this.city = selectedOptions[1].text;
      this.county = selectedOptions[2].text;
      this.street = selectedOptions[3].text;
      this.areaCode = selectedOptions[2].value;
      this.userdetail = "";
      this.showArea = false;
    },
    onDetailInput(val) {
      if (val.trim() && this.province) {
        if (this.timerOut) {
          clearTimeout(this.timerOut);
          this.timerOut = null;
        }
        this.timerOut = setTimeout(() => {
          this.getAddress(val);
        }, 500);
      } else {
        clearTimeout(this.timerOut);
        this.timerOut = null;
        this.searchResult = [];
      }
    },
    getAddress(val) {
      var myGeo = new BMap.Geocoder();
      let that = this;
      myGeo.getPoint(
        val,
        function(point) {
          if (point) {
            console.log("ll", point);
            let map = new BMap.Map("allmap");
            map.centerAndZoom(new BMap.Point(point.lng, point.lat), 11);
            that.getSeach(map, val);
          }
        },
        this.province
      );
    },
    getSeach(map, val) {
      // let map = new BMap.Map("allmap");
      // map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 11);
      // //创建地址解析器实例
      // // 将地址解析结果显示在地图上，并调整地图视野
      let that = this;
      var options = {
        renderOptions: { map: map },
        onSearchComplete: function(results) {
          console.log(1122);
          if (local.getStatus() === BMAP_STATUS_SUCCESS) {
            that.searchResult = [];
            that.showPopover = true;
            for (var i = 0; i <= results.getCurrentNumPois(); i++) {
              let m = results.getPoi(i);
              if (m) {
                let index = m.address.indexOf(that.county) || 0;
                let len = that.county.length;
                that.searchResult.push({
                  text: m.address.substring(index + len) + " " + m.title || "",
                  icon: "location-o",
                  className: "action-dom"
                });
              }
            }
            console.log("kkk", that.searchResult);
          } else {
            Toast("您输入的地址没有解析到结果！");
          }
        }
      };
      var local = new BMap.LocalSearch(map, options);
      let name = this.city + this.county + this.street + val;
      local.search(name);
    },
    onSelect(val) {
      //选中
      this.userdetail = val.text;
    },
    submitClik() {},
    setHeadPosition(e) {
      const that = this;

      if (that.$refs.dxPopper) {
        // 点击其他地方关闭 popover气泡
        if (e.target.id !== "dxTextare") {
          that.showPopover = false;
        }
      }
    }
  },
  destroyed() {
    document.removeEventListener("mouseup", this.setHeadPosition);
  }
};
</script>
<style lang="scss" scoped>
@import "~@/styles/common.scss";

.cen-img {
  text-align: center;
  img {
    width: 100%;
  }
}
.main-cen {
  font-size: 14px;
  padding: 0 6px;
  border-radius: 12px;
  overflow: hidden;
  .cen-h-tit {
    color: #f82a2e;
    background-color: #fff;
    padding: 8px 6px;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.address-tit {
  font-size: 12px;
  color: #ec5356;
  padding: 10px 20px 10px 26px;
  background-color: #fff;
  position: relative;
  border-top: 1px solid rgba(112, 112, 112, 0.16);
  border-bottom: 1px solid rgba(112, 112, 112, 0.16);
  &::before {
    content: "";
    position: absolute;
    left: 16px;
    top: 13px;
    height: 8px;
    width: 2px;
    background: #ec5356;
  }
}
.contrain-footer {
  padding: 10px 16px 30px 16px;
  p {
    font-size: 12px;
    color: #cacaca;
    margin-bottom: 12px;
  }
}
</style>
