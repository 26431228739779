<template>
    <div class="order">
        <div class="herad">

            <van-icon name="arrow-left" @click="go()" size="30" />
                                            <div style="flex: 1;position: relative;">
                                                <van-search v-model="mobile" @search="onSearch" @clear="clear" shape="round" background="#fff"
                                                    placeholder="请输入手机号" />
                                        
                                </div>
                                <van-icon name="search" size="22" @click="onSearch" style="padding: 0 6px;" />
        </div>
        <div class="centn" v-for="(item, index) in list">
            <p>快递名称:<span>{{ item.expressName }}</span></p>
            <p>单&ensp;&ensp;&ensp;&ensp;号:<span>{{ item.expressNo }}</span></p>

            <p>产品名称:<span>{{ item.name }}</span></p>
        </div>
    </div>
</template>
  
<script>
import API from "../../api/http";
export default {

    data() {
        return {
            mobile: undefined,
            list: []
        };
    },
    computed: {
        variables() {
            return variables;
        },
        userType() {
            return this.$store.getters["user/userType"];
        },
    },
    created() {

    },
    methods: {
        go() {
            this.$router.go(-1)
        },
        clear() {
            console.log(111);
            this.mobile = ''
        },
        onSearch() {
            if (this.mobile) {
                API.queryExpress({ mobile: this.mobile })
                    .then(res => {
                        console.log(res);
                        this.list = res.data
                    })
            }
        }

    },
};
</script>
<style lang="scss" scoped>
.herad {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    align-items: center;
}

.centn {
    box-sizing: border-box;
    border-radius: 8px;
    
    background-color: #fff;
    margin: 10px;
    padding: 10px;

    p {
        font-size: 15px;
        margin: 8px 0;

        span {
            font-size: 14px;
            color: #999;
        }
    }
}
</style>
  