<template>
  <div class="container">
    <header-back name='兑换教程'></header-back>
    <div class="seach-cell top44">
      <h4>短信兑换方式</h4>

      <!-- <div class="flow-l">
        <p>一：正常验证码兑换</p>
        <p> 二：短信兑换</p>
        <p>三：输入服务密码兑换</p>
      </div> -->
      <div class="flow-l">
        <!-- <p>每个礼品前面会有三个显示，加入购物车、核销订单、需要服务密码</p>
        <p>1、购物车功能：正常验证码兑换</p>
        <p>2、核销订单是短信兑换，用户操作成功点核销订单</p>
        <p>3、此礼品出现需要服务密码：表示这个产品是风控了，要输入服务密码才能兑换</p>
        <p>4、如果一开始这个产品没有显示需要服务密码，提交的时候提示： <span>操作失败,验证码错误，请重新获取！(此产品需要客服密码，请更换其他产品兑换)，</span>这个提示就是出现风控，需要服务密码了，刷新页面；</p>
        <p>
          兑换不需要服务密码的礼品
          出现这个提示：<span>操作失败,验证码错误，请重新获取！(此产品需要客服密码，请更换其他产品兑换)</span>，如果反复提交，系统就会提示：验证码错误，请重新获取，所以出现服务密码提示了就不要再点提交了，更换礼品兑换。如下图：</p>

        <div><img src="../../assets/image/flow1.png"></div>
        <p>5、没有提示需要服务密码的，提交订单那里不用填写服务密码；没有提示需要服务密码的，提交订单那里不用填写服务密码；如下图</p>
        <div><img src="../../assets/image/flow2.png"></div>
        <h4>短信兑换方式</h4> -->
        <div class="flow-l">
          <p class="wb">第一步：先登录用户手机号码-如图1、图2：</p>
          <div><img src="../../assets/image/sms1.png"></div>
          <div><img src="../../assets/image/sms2.png"></div>
          <p class="wb">第二步：选好给用户兑换的礼品，加入购物车-如图3:</p>
          <div><img src="../../assets/image/sms4.png"></div>
          <p class="wb">第三步：把用户的详细地址填写好，点同步地址<span>（切记一定要写详细的地址，短信兑换是修改不了地址的）</span>-如图4:</p>
          <div><img src="../../assets/image/sms5.png"></div>
          <p class="wb">第四步：同步地址显示已同步以后，点发送短信-如图5:</p>
          <div><img src="../../assets/image/sms6.png"></div>
          <p>点完发送短信，产品那边会显示短信已发送，说明短信已经发送到用户手机上了-如图6:</p>
          <div><img src="../../assets/image/sms7.png"></div>
          <p class="wb">第五步：用户会收到这样的短信--如图7:</p>
          <div><img src="../../assets/image/sms8.png"></div>
          <p>然后让用户点击短信里面的链接，点进去是这样的页面-如图8:</p>
          <div><img src="../../assets/image/sms9.png"></div>
          <p>点完打开跳转的页面-如图9:</p>
          <div><img src="../../assets/image/sms10.png"></div>
          <p class="wb">第六步：让用户点发送就可以（发送的此串码是产品的兑换码,发送到10658999中国移动积分商城的）-如图10:</p>
          <div><img src="../../assets/image/sms11.png"></div>
          <p>用户只要点完发送短信后，产品那里会显示待支付，如果未显示待支付那就是用户没有点发送-如图11:</p>
          <div><img src="../../assets/image/sms12.png"></div>
          <p>显示待支付后，让用户返回到短信首页，会收到这样的短信<span>（收到的短信是10658999➕10位不同的数字发送过来的）</span>-如图12:</p>
          <div><img src="../../assets/image/sms13.png"></div>
          <p class="wb">第七步：上面显示了用户兑换的什么礼品什么规格扣减多少积分以及用户的收获信息，如用户没有问题让用户根据短信提醒回复两位数字确认兑换码-如图13:</p>
          <div><img src="../../assets/image/sms14.png"></div>
          <p>回复完以后，用户会收到兑换成功的短信-如图14:</p>
          <div><img src="../../assets/image/sms15.png"></div>
          <p>兑换成功以后，我们平台也会显示兑换成功，此订单就兑换好了-如图15:</p>
          <div><img src="../../assets/image/sms16.png"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import utils from "@/utils/index";
export default {
  components: { HeaderBack },
  data() {
    return {
      seachValue: "",
      list: [],
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.container {
  height: 100%;
  background-color: #fff;
}

.seach-cell {
  margin-top: 30px;
  padding-bottom: 50px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  color: #323233;
  box-sizing: border-box;
  background-color: #d3e2fa;
  h4 {
    text-align: center;
    font-size: 28px;
  }
  p {
    line-height: 36px;
    font-size: 16px;
  }
  .wb {
    font-weight: bold;
  }
  .flow-l {
    margin-top: 16px;
  }
  span {
    color: red;
  }
  img {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
