<template>
  <div
    class="pay-contaior"
  >
   <header-back name='收银台'>
    </header-back>
  <div class="money-t1 top44">实付金额</div>
    <div class="money-tit">
      ¥<span>{{ amount }}</span>
    </div>
    <div class="money-timer">
      剩余支付时间 <span>{{ rocallTime }}</span> 
    </div>
    <div class="pay-Way">
      <p>支付方式</p>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell
            clickable
            @click="radio = '1'"
            title-class="pay-radio"
          >
            <template #title>
              <img src="../../assets/image/aliPay.png" />
              <span class="custom-title">支付宝支付</span>
            </template>
            <template #right-icon>
              <van-radio
                name="1"
                checked-color="#F35656"
              />
            </template>
          </van-cell>
          <van-cell
            clickable
            @click="radio = '2'"
            title-class="pay-radio"
          >
            <template #title>
              <img src="../../assets/image/wxPay.png" />
              <span class="custom-title">微信支付</span>
            </template>
            <template #right-icon>
              <van-radio
                name="2"
                checked-color="#F35656 "
              />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      
     
    </div>
    <div class="pay-footer">
 <van-button
            block
            type="info"
             color="linear-gradient(180deg, #F56B6E 0%, #DF3033 100%)"
            @click="onPay()"
          >
            确认支付
          </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import HeaderBack from "../../components/header-back";
export default {
  name: "pay",
  components: { HeaderBack },
  data() {
    return {
      amount: 0,
      pid: "",
      rocallTime: "",
      radio: "1",
      weChatParameter: {},
      wechatpayType: "wxpay"
    };
  },
  created() {
    // this.amount = this.$route.query.amount;
    this.pid = this.$route.query.pid;
    this.ComputetTime();
  },
  methods: {
    onPay() {
      //立即支付
      if (this.radio === "1") {
        //支护宝支付
        // this.fetchVideoPay();
      } else {
        //微信支付
        // this.is_weixn();
      }
    },
    fetchVideoPay() {
      let param = {
        orderId: "", //交易订单号
        // 如果想问支付成功后的跳转呢，你则需要给一个链接给到后端，后端传给阿里或者微信，成功后自己跳的。
        url: "youback" //成功后的回调地址 选传
      };
      this.$api.orderpage.videoAliPay(param).then(res => {
        const div = document.createElement("div");
        div.id = "alipay";
        div.innerHTML = res.data.aliHtml; //res.data是返回的表单
        document.body.appendChild(div);
        document.forms[0].submit();
        document.body.removeChild(form);
      });
    },
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信内
        this.wechatpayType = "wxpay";
        // return true;
      } else {
        //微信外
        this.wechatpayType = "wxpay_php";
      }
      this.handelPay();
    },
    handelPay() {
      if (this.wechatpayType == "wxpay") {
        // console.log("微信内支付")
        let data = {
          amount: this.amount
        };
        this.$http.insideWeChatPay(data).then(res => {
          if (res.data.code === 200) {
            this.weChatParameter = res.data.data;
            // console.log(this.weChatParameter,"微信内支付需要参数")
            this.weixinPay();
          } else {
            Toast({
              message: res.data.msg,
              position: "middle",
              duration: 1000
            });
          }
        });
      } else if (this.wechatpayType == "wxpay_php") {
        // console.log("微信外支付")

        let data = {
          amount: this.number,
          trade_type: "MWEB"
        };

        this.$http.outsideWeChatPay(data).then(res => {
          if (res.data.code === 200) {
            //支付成功后，指定的回跳页面
            // const path = escape(
            //   `${process.env.VUE_APP_BASE_API}/#/exchange?type=${type}&pid=${this.pid}`
            // );
            let url =
              res.data.MWEB_URL +
              "&redirect_url=" +
              encodeURIComponent(
                `${process.env.VUE_APP_BASE_API}/#/exchange?type=${type}&pid=${
                  this.pid
                }`
              );
            //         正常流程用户支付完成后会返回至发起支付的页面，如需返回至指定页面，则可以在MWEB_URL后拼接上redirect_url参数，来指定回调页面。
            // 如，您希望用户支付完成后跳转至https://www.wechatpay.com.cn，则可以做如下处理：
            // 假设您通过统一下单接口获到的MWEB_URL= https://wx.tenpay.com/cgi-bin/mmpayweb-bin/checkmweb?prepay_id=wx20161110163838f231619da20804912345&package=1037687096
            // 则拼接后的地址为MWEB_URL= https://wx.tenpay.com/cgi-bin/mmpayweb-bin/checkmweb?prepay_id=wx20161110163838f231619da20804912345&package=1037687096&redirect_url=https%3A%2F%2Fwww.wechatpay.com.cn

            window.location.replace(url); //这里是后端返回的URL直接进行跳转即可完成微信外支付
          } else {
            Toast({
              message: res.data.msg,
              position: "middle",
              duration: 1000
            });
          }
        });
      }
    },
    ComputetTime(data) {
      ////支付倒计时
      // let st = data.currentTime.replace(/\-/g, "/"), //当前服务器时间
      //   ct = data.formatCreateTime.replace(/\-/g, "/"); //创建订单时间
      // let ts = new Date(st).getTime(),
      //   tc = new Date(ct).getTime();
      let ts = new Date().getTime();
      let tc = new Date().getTime();
      let cm = 15 * 60 * 1000 - (ts - tc);
      this.runBack(cm);
    },
    runBack(cm) {
      if (cm > 0) {
        cm > 60000
          ? (this.rocallTime =
              (new Date(cm).getMinutes() < 10
                ? "0" + new Date(cm).getMinutes()
                : new Date(cm).getMinutes()) +
              ":" +
              (new Date(cm).getSeconds() < 10
                ? "0" + new Date(cm).getSeconds()
                : new Date(cm).getSeconds()))
          : (this.rocallTime =
              "00:" +
              (new Date(cm).getSeconds() < 10
                ? "0" + new Date(cm).getSeconds()
                : new Date(cm).getSeconds()));
        let _msThis = this;
        setTimeout(function() {
          cm -= 1000;
          _msThis.runBack(cm);
        }, 1000);
      } else {
        //超过时间未支付
      }
    },
    weixinPay(data) {
      //解决微信内置对象报错
      var vm = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            vm.onBridgeReady(data),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", vm.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", vm.onBridgeReady(data));
        }
      } else {
        vm.onBridgeReady();
      }
    },
    onBridgeReady() {
      //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
      var vm = this;
      var timestamp = Math.round(vm.weChatParameter.timeStamp).toString();
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          debug: true,
          appId: vm.weChatParameter.appId, //公众号名称，由商户传入
          timeStamp: timestamp, //时间戳，自1970年以来的秒数
          nonceStr: vm.weChatParameter.nonceStr, //随机串
          package: vm.weChatParameter.package,
          signType: vm.weChatParameter.signType, //微信签名方式：
          paySign: vm.weChatParameter.paySign, //微信签名
          jsApiList: ["chooseWXPay"]
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast({
              message: "支付成功",
              position: "middle",
              duration: 3000
            });
            this.$router.push({
              path: "exchange",
              query: {
                type: "pay",
                pid: this.pid
              }
            });
            // vm.number = null;
            // vm.$router.go(-1);
            //window.location.href = vm.BASE_URL + 'index.html#/depositResult'
          } else {
            Toast({
              message: "支付失败",
              position: "middle",
              duration: 3000
            });
          }
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.pay-contaior {
  padding: 16px;
  & > .simple-header {
    background: transparent;
  }
  .money-t1 {
    text-align: center;
    font-size: 18px;
    color: #090909;
  }
  .money-tit {
    font-size: 24px;
    padding: 10px 0;
    font-weight: 500;
    color: #090909;
    text-align: center;
    & > span {
      font-size: 24px;
    }
  }
  .money-timer {
    width: 100%;
    padding-bottom: 10px;
    text-align: center;

    font-size: 14px;
    font-weight: 500;
    & > span {
      color: #ea4727;
    }
  }
  .pay-Way {
    background: #fff;
    border-radius: 12px;
    margin-top: 30px;
    & > p {
      padding: 16px;
      font-weight: 500;
      color: #414141;
      font-size: 16px;
    }
  }
  .pay-footer {
    margin-top: 40px;
  }
  .pay-radio img {
    width: 25px;
    height: 25px;
    vertical-align: bottom;
    margin-right: 15px;
  }
  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: none;
  }
}
</style>
