<template>
    <div>
        <header-back name='商城公告'></header-back>
        <div class="box">
            <div class="tit">
                <div>
                    <p style="font-size: 22px;"> 端午放假通知</p>
                    <p style="color: #999;font-weight:500"> 2023年6月20日</p>
                </div>
                <div style="width: 100%;border-top:1px solid #e5e5e5;margin: 16px 0;"></div>
                <div @click="delView(2)">
                    <p>查看详情</p><van-icon name="arrow" color="#999" size="20" />
                </div>
            </div>
            <div class="tit">
                <div>
                    <p style="font-size: 22px;"> 五一放假通知</p>
                    <p style="color: #999;font-weight:500"> 2023年4月28日</p>
                </div>
                <div style="width: 100%;border-top:1px solid #e5e5e5;margin: 16px 0;"></div>
                <div @click="delView(1)">
                    <p>查看详情</p><van-icon name="arrow" color="#999" size="20" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderBack from "../../../components/header-back";
export default {
    components: { HeaderBack },
    data() {
        return {

        }
    },
    methods: {
        delView(e) {
            this.$router.push(`/delView?id=${e}`);
        }
    }

}
</script>
<style scoped>
.box {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 42px;
    background-color: #f5f5f5;
}

.tit {
    background-color: #FFF;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.tit div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tit div p {
    font-size: 16px;
    font-weight: 600;
}
</style>