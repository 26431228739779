<template>
    <div>

        <header-back name='商城公告'></header-back>
        <div class="box" v-if="show == 2">
            <p style="font-size: 24px;font-weight:600;margin-bottom: 6px;"> 端午放假通知：</p>

            &emsp;&emsp;端午放假通知:
            尊敬的各代理渠道，我司放假时间为6月22日-6月24日，6月25日恢复上班
            放假期间平台关闭提现功能，可以正常做单，有任何问题请致电对接负责人
            感谢合作伙伴一直以来的支持，在此肥猫商城全体员工提前️祝大家端午节快乐，愿我们的合作越来越好，事业更上一层楼。

            <p style="width: 100%;text-align: right; margin-top: 6px;"> 2023年6月20日</p>
        </div>
        <div class="box" v-if="show == 1">
            <p style="font-size: 24px;font-weight:600;margin-bottom: 6px;"> 五一放假通知：</p>

            &emsp;&emsp;尊敬的各代理渠道，我司放假时间为4月29日-5月3日，5月4日恢复上班
            放假期间平台关闭提现功能，可以正常做单，有任何问题请致电对接负责人
            在此肥猫商城全体员工提前️大家五一劳动节快乐。
            <p style="width: 100%;text-align: right; margin-top: 6px;">2023年4月28日</p>
        </div>
    </div>
</template>
<script>
import HeaderBack from "../../../components/header-back";
export default {
    components: { HeaderBack },
    data() {
        return {
            show: 1
        }
    },
    created() {
        console.log(this.$route.query.id);
        this.show = this.$route.query.id
        console.log(this.show);
    }


}
</script>
<style scoped>
.box {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 42px;
    font-size: 20px;
    line-height: 34px;
    background-color: #f5f5f5;
}
</style>