<template>
  <div class="container">
    <header-back name='绑定支付宝'></header-back>
    <section class="top44">
      <div class="abb-bank">
       
        <div class="bank-f">
          <van-cell-group style="background-color:transparent">
            <van-field
              clearable
              v-model="backMap.nickname"
              label="姓名："
              placeholder="请输入姓名"
            />
          
            <van-field
              clearable
              v-model="backMap.alipayNo"
              label="支付宝："
              placeholder="请输入支付宝账号"
            />
          </van-cell-group>
        </div>
        <div class="bank-btn">
          <van-button
            :color="variables.textColor"
            bindtap="onNext"
            size="small"
            type="primary"
            @click='addBack'
          >保存</van-button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";
import variables from "@/styles/variables.scss";
import API from "../../api/http";
import validateRule from "@/utils/validateRule";
import { setTimeout } from "timers";
export default {
  components: { HeaderBack },
  data() {
    return {
      backMap: {
        nickname: "",
        idCard: "",
        number: "",
        mobile: "",
        BankCode: "",
        bankBrancheId: "",
        province: "",
        alipayNo: ""
      }
    };
  },
  computed: {
    variables() {
      return variables;
    }
  },
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      API.getUserBankList().then(res => {
        let list = res.data.list;
        console.log("kk", list);
        if (list.length > 0) {
          let data = list[0];
          this.backMap.nickname = data.bankUserNickName;
          this.backMap.alipayNo = data.alipayAccount;
        }
      });
    },

    addBack() {
      if (!this.backMap.nickname) {
        Toast("请输入姓名");
        return;
      }

      if (!this.backMap.alipayNo) {
        Toast("请输入支付宝账号");
        return;
      }

      let data = {
        nickname: this.backMap.nickname,
        idCard: this.backMap.idCard,
        number: this.backMap.number.replace(/\s+/g, ""),
        mobile: this.backMap.mobile,
        bankBrancheId: this.backMap.bankBrancheId,
        alipayNo: this.backMap.alipayNo
      };

      API.postAddBank(data).then(res => {
        Toast("保存成功");
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      });
    }
  }
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 0 15px;
  box-sizing: border-box;
}
.bank-tit {
  padding-top: 30px;
  text-align: center;
  color: #030303;
  font-size: 20px;
  font-weight: 500;
}
.tit2 {
  margin-top: 10px;
  font-size: 16px;
  color: #292929;
}
.bank-f {
  margin-top: 30px;
}
.bank-btn {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  .van-button {
    padding: 10px 30px;
  }
}
</style>
